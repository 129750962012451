<template>
  <v-container>
    <v-sheet>Hi! This is Austin Bontrager's homepage. </v-sheet>
  </v-container>
</template>

<script>
export default {
  name: "Home",
  components: {},
};
</script>
